import { Link, StaticQuery, graphql } from "gatsby";

import React from "react";

function Footer() {
  return (
    <div className="bg-white pt-4 ">
      <div className="px-lg-3 pt-2 pb-4">
        <div className="mx-auto px-3" style={{ maxWidth: 80 + "rem" }}>
          <div className="row">
            <div className="col-sm-3">
              <div className="widget widget-links  pb-2 mb-4">
                <StaticQuery
                  query={graphql`
                    query footerCategories {
                      hasura {
                        categories(
                          order_by: { sort: asc }
                          where: { active: { _eq: true } }
                        ) {
                          name
                          slug
                          id
                          sort
                        }
                      }
                    }
                  `}
                  render={(data) => (
                    <div>
                      <div className="widget-title">Produkte</div>
                      <ul className="widget-list">
                        {data?.hasura?.categories.map((category, index) => {
                          return (
                            <li
                              className="widget-list-item"
                              key={`cat${index}`}
                            >
                              <Link
                                className="widget-list-link"
                                to={`/produkte/${category.slug}`}
                              >
                                {category.name}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="widget  pb-2 mb-4">
                <div className="widget-title ">Follow us</div>

                <a
                  className="social-btn  sb-facebook mr-2 mb-2"
                  href="https://www.facebook.com/punscheninwien"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="facebook page"
                >
                  <i className="czi-facebook"></i>
                </a>
                <a
                  className="social-btn  sb-instagram mr-2 mb-2"
                  href="https://www.instagram.com/punscheninwien/"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Instagram page"
                >
                  <i className="czi-instagram"></i>
                </a>
              </div>

              <div className="widget  pb-2 mb-4">
                <div className="widget-title ">Kontakt</div>

                <div className="d-flex align-items-center">
                  <span className="czi-mail text-primary"></span>
                  <span className="ml-3">
                    <a
                      className=""
                      href="mailto:office@punsch-taxi.at?subject=Anfrage Punsch-Mobil"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="contact"
                    >
                      office@punsch-taxi.at
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="widget widget-links  pb-2 mb-4">
                <div className="widget-title ">Our Websites</div>
                <ul className="widget-list">
                  <li className="widget-list-item">
                    <a
                      className="widget-list-link"
                      href="http://www.creperie-mobile.at"
                      target="_blank"
                      rel="noreferrer"
                    >
                      La Crêperie Mobile
                    </a>
                  </li>
                  <li className="widget-list-item">
                    <a
                      className="widget-list-link"
                      href="http://www.vintage-foodtrucks.at/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Vintage Foodtrucks
                    </a>
                  </li>
                  <li className="widget-list-item">
                    <a
                      className="widget-list-link"
                      href="https://www.punscheninwien.at/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Punschen In Wien
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-sm-3">
              <div className="widget widget-links  pb-2 mb-4">
                <div className="widget-title ">Company</div>
                <ul className="widget-list">
                  <li className="widget-list-item">
                    <Link
                      className="widget-list-link"
                      to="/impressum"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Impressum
                    </Link>
                  </li>
                  <li className="widget-list-item">
                    <Link
                      className="widget-list-link"
                      to="/impressum"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Datenschutz
                    </Link>
                  </li>
                  <li className="widget-list-item">
                    <Link
                      className="widget-list-link"
                      to="/agb"
                      target="_blank"
                      rel="noreferrer"
                    >
                      AGB
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-lg-3" style={{ backgroundColor: "#ecf2f7" }}>
        <div
          className="d-sm-flex justify-content-between align-items-center mx-auto px-3"
          style={{ maxWidth: 80 + "rem" }}
        >
          <div className="font-size-xs  opacity-50 text-center text-sm-left py-3">
            <a
              className="widget-list-link"
              href="https://creperie-mobile.at/"
              target="_blank"
              rel="noreferrer"
            >
              © Punsch-Mobil by La Crêperie Mobile
            </a>
          </div>
          <div className="py-3">
            {/* <img className="d-block mx-auto mx-sm-left" width="187" src="img/cards-alt.png" alt="Payment methods" /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
