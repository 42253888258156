import React, { useContext } from "react";

import { CartContext } from "../cartContext";
import ImageFixed from "./ImageFixed";

function CartMiniList() {
  const { cart, removeItem } = useContext(CartContext);

  const handleClick = (id) => removeItem(id);

  const Item = ({ name, price, amount = 1, image, selectedOption, id, marmelades, punsches, turbos }) => {
    let optionText = null;
    if (selectedOption.hasOwnProperty("name")) {
      optionText = `${selectedOption.description} `;
    }
    let displayedText = "";

    const formatList = (list) => {
      return (
        <div className="">
          {list.map((e, i) => (
            <div className="d-flex " key={i}>
              <div className="mr-1">
                <small>{e.quantity}x </small>
              </div>
              <div className="">
                <small>{e.name}</small>
              </div>
            </div>
          ))}
        </div>
      );
    };
    return (
      <div className="widget-cart-item ">
        <button className="close text-danger" type="button" aria-label="Remove" onClick={() => handleClick(id)}>
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="media align-items-center ">
          <li className="d-block mr-3">
            <ImageFixed width="64" filename={image} alt={name} />
          </li>
          <div className="media-body">
            <h6 className="widget-product-title">{name}</h6>
            {optionText ? <small className="mr-2">{optionText}</small> : null}
            {formatList(punsches)}
            {turbos.length > 0 ? formatList(turbos) : null}

            <div className="widget-product-meta">
              <span className="text-accent mr-2">{price}€</span>
              <span className="text-muted">x {amount}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="overflow-hidden">
      {cart.map((item, index) => {
        return (
          <Item
            key={`item${index}`}
            id={item.id}
            selectedOption={item.selectedOption}
            name={item.name}
            price={item.price}
            amount={item.amount}
            image={item.poster}
            marmelades={item.marmelades}
            punsches={item.punsches}
            turbos={item.turbos}
          ></Item>
        );
      })}
    </div>
  );
}

export default CartMiniList;
