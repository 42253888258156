import React, { createContext, useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";

function getCartFromLocalStorage() {
  return localStorage.getItem("cart")
    ? JSON.parse(localStorage.getItem("cart"))
    : [];
}

const defaultState = {
  cart: [],
  inCart: () => {
    return false;
  },
  clearCart: () => {
    return false;
  },
  getItem: () => {
    return 0;
  },
  countCart: () => {
    return 0;
  },
  getMarmelades: () => {
    return 0;
  },
  getOptions: () => {
    return 0;
  },
  categoriesInCart: () => {
    return 0;
  },
  minimumOrderAmount: () => {
    return 0;
  },
  setIsCartReady: () => {
    return 0;
  },
  getIngredients: () => {
    return 0;
  },
  hasBox: () => {
    return false;
  },
  hasOnlyGutschein: () => {
    return false;
  },
  orderMini: () => {
    return 0;
  },
};

const CartContext = createContext(defaultState);

function CartProvider({ children }) {
  const [cart, setCart] = useState(getCartFromLocalStorage());
  const [total, setTotal] = useState(0);
  const [orderMini, setOrderMini] = useState(0);
  const [cartItems, setCartItems] = useState(0);
  const [setIsCartReady] = useState(false);
  const [distance, setDistance] = useState(0);
  const [single, setsingle] = useState(0);
  const [double, setdouble] = useState(0);
  const data = useStaticQuery(graphql`
    query Marmelades {
      hasura {
        marmelades {
          id
          name
        }
        categories {
          id
          name
        }
      }
    }
  `);
  const listMarmelades = data?.hasura?.marmelades;
  const listCategories = data?.hasura?.categories;

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));

    let newTotal = cart.reduce((total, cartItem) => {
      return (total += cartItem.amount * cartItem.price);
    }, 0);
    newTotal = parseFloat(newTotal.toFixed(2));
    setTotal(newTotal);
    // cart items
    let newCartItems = cart.reduce((total, cartItem) => {
      return (total += cartItem.amount);
    }, 0);
    setCartItems(newCartItems);
  }, [cart]);

  /** REMOVE ITEM FROM CART */
  const removeItem = (id) => {
    setCart([...cart].filter((item) => item.id !== id));
  };
  /** INCREASE AMOUNT */
  const increaseAmount = (id) => {
    const newCart = [...cart].map((item) => {
      return item.id === id
        ? { ...item, amount: item.amount + 1 }
        : { ...item };
    });
    setCart(newCart);
  };
  /** DECREASE AMOUNT */
  const decreaseAmount = (id, amount) => {
    if (amount === 1) {
      removeItem(id);
      return;
    } else {
      const newCart = [...cart].map((item) => {
        return item.id === id
          ? { ...item, amount: item.amount - 1 }
          : { ...item };
      });

      setCart(newCart);
    }
  };
  /* ADD TO CART*/
  const addToCart = (
    product,
    option,
    marmelades,
    selectPunsches,
    totalPunsches = 0,
    selectTurbos,
    totalTurbos = 0
  ) => {
    const { id, name, price, poster } = product;
    const item = [...cart].find((item) => item.id === id);
    let finalprice = option.hasOwnProperty("price")
      ? option.price
      : totalPunsches > 0
      ? totalPunsches + totalTurbos
      : price + totalTurbos;
    let formattedProduct = ``;

    //finalprice += totalPunsches;
    if (item) {
      formattedProduct += `${item.amount}x ${item.name} `;
      increaseAmount(id);
      return;
    } else {
      formattedProduct += `${
        listCategories.find((el) => el.id === product?.categoryByCategory.id)
          .name
      }\n\r`;
      formattedProduct +=
        product?.name !== "Heißer Punsch" ? `${product?.name}\n\r` : ``;
      formattedProduct += option.hasOwnProperty("name")
        ? ` - ${option.name} ${option.description} \n\r`
        : ``;
      formattedProduct +=
        selectPunsches.length > 0
          ? selectPunsches.map((e) => ` - ${e.quantity}ltr ${e.name}\n\r`)
          : ``;
      formattedProduct += selectTurbos.map(
        (e) => ` - ${e.quantity}x ${e.name}\n\r`
      );
      formattedProduct += marmelades.map((e) =>
        e !== 0 ? ` - ${listMarmelades.find((el) => el.id === e).name}\n\r` : ``
      );

      const _formattedProduct = formattedProduct.replace(/[,]+/g, "");
      const newItem = {
        id,
        name,
        category: product?.categoryByCategory.id,
        categoryName: product?.categoryByCategory.name,
        price: finalprice,
        poster,
        amount: 1,
        selectedOption: option,
        marmelades: marmelades,
        punsches: selectPunsches,
        turbos: selectTurbos,
        formattedProduct: _formattedProduct,
      };
      const newCart = [...cart, newItem];
      setCart(newCart);
    }
  };
  /** EMPTY CART */
  const clearCart = () => {
    setCart([]);
  };

  const inCart = (productId) => {
    return [...cart].find((item) => item.id === productId) ? true : false;
  };

  const getItem = (productId) => {
    const prod = [...cart].find((item) => item.id === productId);
    return prod ? prod.amount : 0;
  };

  const countCart = () => {
    const countProducts = cart.reduce((total, cartItem) => {
      return (total += cartItem.amount);
    }, 0);
    const totalCart = total;
    const feesToDeliver = orderMini - totalCart;
    let finalFees = feesToDeliver <= 0 ? 0 : feesToDeliver;
    let totalToPay = hasOnlyGutschein()
      ? totalCart
      : finalFees > totalCart
      ? orderMini
      : totalCart;
    return { countProducts, totalCart, finalFees, totalToPay };
  };
  const getMarmelades = (productId) => {
    const prod = [...cart].find((item) => item.id === productId);
    const hasMarmelade = prod ? prod.hasOwnProperty("marmelades") : null;
    return hasMarmelade ? prod.marmelades : [];
  };
  const getIngredients = (productId) => {
    const prod = [...cart].find((item) => item.id === productId);
    const hasIngredient = prod ? prod.hasOwnProperty("ingredients") : null;
    return hasIngredient ? prod.ingredients : [];
  };
  const getOptions = (productId) => {
    const product = [...cart].find((item) => item.id === productId);
    return product ? product?.selectedOption : null;
  };
  const categoriesInCart = () => {
    const categoriesInCart = [];
    cart.map((el, i) => {
      return categoriesInCart.push(el.category);
    });
    return categoriesInCart;
  };

  const minimumOrderAmount = (single, double) => {
    const inCartCategories = categoriesInCart(); // return [] category id
    let minimimOrder = 0;
    if (hasOnlyGutschein()) {
      setOrderMini(0);
      return 0;
    }
    if (single) {
      minimimOrder = single;
      inCartCategories.map((cic, ind) => {
        if (cic === 4 || cic === 1) minimimOrder = double;
        return true;
      });
      // if there is only vouchers, no need to pay delivery fees
      if (inCartCategories.length === 1) {
        if (inCartCategories[0] === 100) {
          minimimOrder = 0;
          return true;
        }
      }

      const _hasBox = hasBox();
      if (_hasBox) minimimOrder = double;
    }

    minimimOrder = parseFloat(minimimOrder.toFixed(0));
    setOrderMini(minimimOrder);
    return minimimOrder;
  };

  // le produit devra etre recherché le lendemain
  const hasBox = () => {
    const product = [...cart].find(
      (item) =>
        (item.id === 1 ||
          item.id === 2 ||
          item.id === 3 ||
          item.id === 4 ||
          item.id > 999) &&
        item.id < 9999
    );
    return product ? true : false;
  };

  // Le produit necessite unjour de prepapration
  const hasDayPlus = () => {
    const product = [...cart].find((item) => item.id > 1999 && item.id < 9999);
    return product ? true : false;
  };

  const hasOnlyGutschein = () => {
    let anotherCategory = false;
    const inCartCategories = categoriesInCart();
    inCartCategories.map((cic, ind) => {
      if (cic !== 100) {
        anotherCategory = true;
        return false;
      }
    });
    // console.log("hasonly gutsch ?");
    // console.log(!anotherCategory);
    return !anotherCategory;
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        cartItems,
        total,
        removeItem,
        increaseAmount,
        decreaseAmount,
        addToCart,
        clearCart,
        inCart,
        getItem,
        countCart,
        getMarmelades,
        getIngredients,
        getOptions,
        categoriesInCart,
        minimumOrderAmount,
        setIsCartReady,
        orderMini,
        distance,
        single,
        setsingle,
        double,
        setdouble,
        setDistance,
        hasBox,
        hasDayPlus,
        hasOnlyGutschein,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}

export { CartContext, CartProvider };
