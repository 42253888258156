import React, { createContext, useEffect, useState } from "react";

function getFromLocalStorage() {
  return localStorage.getItem("checkout") ? JSON.parse(localStorage.getItem("checkout")) : [];
}

const defaultState = {
  checkout: [],
  saveForm: () => {
    return 0;
  },
  saveFormObj: () => {
    return 0;
  },
};

const FormContext = createContext(defaultState);

function FormProvider({ children }) {
  const [checkout, setCheckout] = useState(getFromLocalStorage());

  useEffect(() => {
    localStorage.setItem("checkout", JSON.stringify(checkout));
  }, [checkout]);

  const saveForm = (form) => {
    const tmp = [];
    tmp.push(form);
    setCheckout(tmp);
  };
  const saveFormObj = (form) => {
    setCheckout(form);
  };
  return <FormContext.Provider value={{ checkout, saveForm, saveFormObj }}>{children}</FormContext.Provider>;
}

export { FormContext, FormProvider };
