exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-agb-js": () => import("./../../../src/pages/agb.js" /* webpackChunkName: "component---src-pages-agb-js" */),
  "component---src-pages-checkout-01-js": () => import("./../../../src/pages/Checkout01.js" /* webpackChunkName: "component---src-pages-checkout-01-js" */),
  "component---src-pages-checkout-02-js": () => import("./../../../src/pages/Checkout02.js" /* webpackChunkName: "component---src-pages-checkout-02-js" */),
  "component---src-pages-checkout-03-js": () => import("./../../../src/pages/Checkout03.js" /* webpackChunkName: "component---src-pages-checkout-03-js" */),
  "component---src-pages-error-js": () => import("./../../../src/pages/error.js" /* webpackChunkName: "component---src-pages-error-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-spielefb-js": () => import("./../../../src/pages/spielefb.js" /* webpackChunkName: "component---src-pages-spielefb-js" */),
  "component---src-pages-spieleig-js": () => import("./../../../src/pages/spieleig.js" /* webpackChunkName: "component---src-pages-spieleig-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-templates-catalog-js": () => import("./../../../src/templates/catalog.js" /* webpackChunkName: "component---src-templates-catalog-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-single-product-js": () => import("./../../../src/templates/single-product.js" /* webpackChunkName: "component---src-templates-single-product-js" */)
}

