import { Link, StaticQuery, graphql } from "gatsby";

import React from "react";

const Sidebar = () => {
  const Category = ({ category }) => {
    // If only one product counnted, go to product page
    let link = `/produkte/${category.slug}`;
    // const slug = slugish(category.products[0].name);
    // if (category.products_aggregate.aggregate.count === 1) {
    //   link = `/${category.slug}/${slug}/`;
    // }

    return (
      <div className="card border-bottom">
        <div className="card-header">
          <h3 className="accordion-heading font-size-base px-grid-gutter">
            <Link className="collapsed py-3" to={link} data-dismiss="sidebar">
              <span className="d-flex align-items-center">
                <i
                  className={`czi-${category.icon} font-size-lg opacity-60 mt-n1 mr-2`}
                  style={{ zIndex: 10 }}
                ></i>
                {category.name}
              </span>
            </Link>
          </h3>
        </div>
      </div>
    );
  };

  return (
    <StaticQuery
      query={graphql`
        query AllCategories {
          hasura {
            categories(
              order_by: { sort: asc }
              where: { active: { _eq: true } }
            ) {
              icon
              name
              slug
              id
              products_aggregate {
                aggregate {
                  count
                }
              }
              products {
                name
              }
            }
          }
        }
      `}
      render={(data) => (
        <aside
          className="cz-sidebar cz-sidebar-fixed"
          id="sideNav"
          style={{ paddingTop: 3 + "rem" }}
        >
          <button
            className="close"
            type="button"
            data-dismiss="sidebar"
            aria-label="Close"
          >
            <span className="d-inline-block font-size-xs font-weight-normal align-middle">
              Close sidebar
            </span>
            <span
              className="d-inline-block align-middle ml-2"
              aria-hidden="true"
            >
              &times;
            </span>
          </button>
          <div className="cz-sidebar-inner">
            <ul
              className="nav nav-tabs nav-justified mt-2 mt-lg-4 mb-0"
              id="navBarSb"
            >
              <li className="nav-item">
                <Link
                  className="nav-link font-weight-medium active"
                  data-dismiss="sidebar"
                  to={`/produkte`}
                >
                  <i className="font-size-lg text-danger mt-n1 mr-2"></i>
                  Produkte
                </Link>
              </li>
            </ul>
            <div className="cz-sidebar-body pt-3 pb-0" data-simplebar>
              <div className="tab-content">
                {/* <!-- Categories--> */}
                <div
                  className="sidebar-nav tab-pane fade show active"
                  id="categories"
                  role="tabpanel"
                >
                  <div className="widget widget-categories">
                    <div className="accordion" id="shop-categories">
                      {data?.hasura?.categories.map((category, index) => (
                        <Category key={`cat${index}`} category={category} />
                      ))}
                    </div>
                  </div>
                </div>
                {/* <!-- Menu--> */}
              </div>
            </div>
          </div>
        </aside>
      )}
    />
  );
};

export default Sidebar;
