import { CartProvider } from "./src/cartContext";
import { FormProvider } from "./src/formContext";
import Layout from "./src/components/layout";
import React from "react";
import { UserProvider } from "./src/userContext";

export const onClientEntry = () => {
  console.log("We've started!");
  const LogRocket = require("logrocket");
  LogRocket.init("viem2e/punsch-taxi");
  // LogRocket.getSessionURL(function (sessionURL) {
  //   gtag("send", {
  //     hitType: "event",
  //     eventCategory: "LogRocket",
  //     eventAction: sessionURL,
  //   });
  // });
};
// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element, props }) => {
  return (
    <UserProvider>
      <CartProvider>
        <FormProvider>
          <Layout {...props}>{element}</Layout>
        </FormProvider>
      </CartProvider>
    </UserProvider>
  );
};
