import React, { useContext } from "react";

import { CartContext } from "../cartContext";
import { Link } from "gatsby";

function CartMyCart() {
  const { countCart } = useContext(CartContext);
  const totalCart = countCart().totalCart;

  return (
    <Link className="navbar-tool-text" to="/Checkout01">
      <small>My Cart</small>
      {totalCart}€
    </Link>
  );
}

export default CartMyCart;
