import React, { useContext } from "react";
import { CartContext } from "../cartContext";

function CartBubble() {
  const { countCart } = useContext(CartContext);
  const count = countCart().countProducts;

  return (
    <div>
      <span className="navbar-tool-label">{count}</span>
      <i className="navbar-tool-icon czi-cart"></i>
    </div>
  );
}

export default CartBubble;
