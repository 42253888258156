import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { StaticQuery } from "gatsby";

const ImageFixed = (props) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fixed(width: 64, height: 64) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const image = data?.images.edges.find((n) => {
        return n.node.relativePath.includes(props.filename);
      });
      if (!image) {
        return <div>error with the image</div>;
      }

      const imageSizes = image.node.childImageSharp.fixed;
      return (
        <Img className={props.className} alt={props.alt} fixed={imageSizes} />
      );
    }}
  />
);

export default ImageFixed;
