import React, { useContext, useEffect } from "react";

import CartBubble from "../components/CartBubble";
import { CartContext } from "../cartContext";
import CartMiniList from "../components/CartMiniList";
import CartMyCart from "../components/CartMyCart";
import { Link } from "gatsby";

export default function Navbar() {
  const { countCart } = useContext(CartContext);
  const totalCart = countCart().totalCart;
  useEffect(() => {
    let openTogglers = document.querySelectorAll('[data-toggle="sidebar"]'),
      closeTogglers = document.querySelectorAll('[data-dismiss="sidebar"]'),
      body = document.querySelector("body");
    for (let i = 0; i < openTogglers.length; i++) {
      openTogglers[i].addEventListener("click", (e) => {
        e.preventDefault();
        let sidebarID = e.currentTarget.getAttribute("href");
        document.querySelector(sidebarID).classList.add("show");
        body.classList.add("offcanvas-open");
      });
    }

    for (let i = 0; i < closeTogglers.length; i++) {
      closeTogglers[i].addEventListener("click", (e) => {
        e.currentTarget.closest(".cz-sidebar").classList.remove("show");
        body.classList.remove("offcanvas-open");
      });
    }
  });

  return (
    <header className="bg-white box-shadow-sm fixed-top border-bottom">
      <nav className="navbar navbar-expand-lg navbar-light ">
        <div className="container-fluid">
          <a
            className="navbar-toggler"
            href="#sideNav"
            data-toggle="sidebar"
            aria-label="navbar toggler"
          >
            <span className="navbar-toggler-icon"></span>
          </a>
          <Link className="" to="/">
            <div className="d-flex flex-column align-items-center">
              <div className=" punsch-taxi">Punsch Mobil</div>
              <small className="punsch-taxi-capt">Punsch Lieferservice</small>
            </div>
          </Link>
          <div className="navbar-toolbar d-flex flex-shrink-0 align-items-center ml-xl-2">
            <div className="navbar-tool dropdown ml-3">
              <Link
                className="navbar-tool-icon-box dropdown-toggle"
                to="/Checkout01"
              >
                <CartBubble />
              </Link>
              <CartMyCart />

              <div
                className="dropdown-menu dropdown-menu-right"
                style={{ width: 20 + "rem" }}
              >
                <div className="widget widget-cart px-3 pt-2 pb-3">
                  <CartMiniList />
                  <div className="d-flex flex-wrap justify-content-between align-items-center pt-3">
                    <div className="font-size-sm mr-2 py-2">
                      <span className="text-muted">Total:</span>
                      <span className="text-accent font-size-base ml-1">
                        {totalCart}€
                      </span>
                    </div>
                    <Link className="btn btn-primary btn-sm" to="/Checkout01">
                      <i className="czi-card mr-2 font-size-base align-middle"></i>
                      Checkout
                      <i className="czi-arrow-right ml-1 mr-n1"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}
