import "../styles/App.css";
import "../styles/theme.css";
import "bootstrap/js/dist/tab.js";

import Footer from "./Footer";
import NavBar from "../components/Navbar";
import React from "react";
import Sidebar from "./Sidebar";

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <NavBar></NavBar>
      <Sidebar></Sidebar>

      <main className="sidebar-fixed-enabled" style={{ paddingTop: 3 + "rem" }}>
        {children}
        <Footer />
      </main>
    </React.Fragment>
  );
};

export default Layout;
