import React, { createContext, useEffect, useState } from "react";

function getFromLocalStorage() {
  return localStorage.getItem("userverification") ? JSON.parse(localStorage.getItem("userverification")) : [];
}

const defaultState = {
  user: [{ age: false, cookie: false }],
  saveUser: () => {
    return 0;
  },
  saveUserObj: () => {
    return 0;
  },
};

const UserContext = createContext(defaultState);

function UserProvider({ children }) {
  const [user, setUser] = useState(getFromLocalStorage());

  useEffect(() => {
    localStorage.setItem("userverification", JSON.stringify(user));
  }, [user]);

  const saveUser = (user) => {
    const tmp = [];
    tmp.push(user);
    setUser(tmp);
  };
  const saveUserObj = (user) => {
    setUser(user);
  };
  return <UserContext.Provider value={{ user, saveUser, saveUserObj }}>{children}</UserContext.Provider>;
}

export { UserContext, UserProvider };
